<template>
  <div class="wh-space-bottom">
    <b-card>
      <edit-field-header
        :title="title"
        @routerBack="$router.back()"
      />

      <edit-field
        :is-edit="false"
        :fields="fields"
        :row-data="row_data"
        post-api="/kiosk/WeHealth/AddKioskPeripheralModel"
        finish-router="kioskPeripheralModel"
        permission=""
      >
      </edit-field>
    </b-card>
  </div>
</template>

<script>
import fields, { reloadFieldData } from './fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import { flattenObject, expandObject } from '@/libs/ez-utils'

export default {
  name: 'Add',
  components: {
    EditFieldHeader,
    EditField,
    fields,
  },

  data() {
    return {
      title: common.getMenuName('kioskPeripheralModel') + ' - ' + common.getI18n('common.add'),
      fields,
      row_data: {
        modelTag: '',
        purposes: [],
        name: '',
        brand: '',
        model: '',
        remark: '',
      }
    }
  },

  created() {
    reloadFieldData()
  },

  mounted() {
  },

  methods: {},
}
</script>

<style scoped>

</style>
